<template>
  <div class="mb-4">
    <b-alert :variant="'primary'" show class="cursor-pointer">
      <div class="alert-body">
        <strong>
          <feather-icon icon="BookIcon" class="mr-25" />
          <!-- <b-badge variant="primary">Pengguna Jasa</b-badge>
          <b-badge class="ml-1" variant="primary">BBM Agents</b-badge>
          <b-badge class="ml-1" variant="primary">Karyawan</b-badge>
          <br> -->
          Monitoring is more than just watching—it's proactive engagement. With
          real-time visitor tracking, our agents are always one step ahead,
          ensuring safety and smooth operations.
        </strong>
      </div>
    </b-alert>
    <b-row class="match-height">
      <!-- <b-col cols="12">
        <b-card>
          <YoTable
            :api-endpoint="apiEndpoint"
            :fields="fields"
            :new-data-template="newDataTemplate"
            :resolve-fetch-response="resolveFetchResponse"
            :resolve-fetch-url="resolveFetchUrl"
            title-tbl="title"
            sub-title-tbl="sub"
            bottom-label="bottom"
            bottom-icon="GitMergeIcon"
            info-page-ability="Manage ( All / Hulu Migas / SAL)"
            placeholder-search="Nama Lengkap"
          />
        </b-card>
      </b-col> -->
      <b-col cols="12" md="12">
        <b-card no-body class="py-1">
          <b-form ref="formListQR" class="repeater-form ml-2 mr-2">
            <b-row>
              <b-col cols="12">
                <!-- LOOPING FIELD -->
                <b-row
                  v-for="(item, index) in form.itemPengunjung"
                  :id="item.fe_id"
                  :key="item.fe_id"
                  ref="rowPengunjung"
                  class="repeater-form ml-1 mr-1"
                >
                  <b-col cols="1">
                    <b-badge pill variant="info" class="mt-0">
                      {{ index + 1 }}
                    </b-badge>
                  </b-col>
                  <b-col cols="10">
                    <hr />
                  </b-col>
                  <b-col cols="1">
                    <b-badge pill variant="danger">
                      <feather-icon
                        icon="XIcon"
                        :style="{ cursor: 'pointer' }"
                        @click="removeItemPengunjung(index)"
                      />
                    </b-badge>
                  </b-col>

                  <b-col lg="6" md="6" cols="6">
                    <b-form-group label="Badge" label-for="vi-forpe-badge_id">
                      <validation-provider
                        #default="{ errors }"
                        name="Badge"
                        vid="vi-forpe-badge_id"
                        rules="required"
                      >
                        <v-select
                          id="vi-forpe-badge_id"
                          v-model="item.badge_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="badgeName"
                          :reduce="(option) => option.value"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                          item-value="value"
                          item-text="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6" md="6" cols="6">
                    <b-form-group
                      label="Nomor Whatsapp * ( Ex: 8155 7777 33 )"
                      label-for="phone"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Nomor Whatsapp *"
                        vid="phone"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            ID (+62)
                          </b-input-group-prepend>
                          <cleave
                            id="phone"
                            v-model="item.phone"
                            class="form-control"
                            :raw="false"
                            :options="options.phone"
                            placeholder="8155 7777 33"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col lg="12" md="12" cols="12">
                    <b-form-group
                      class="mt-1"
                      label=""
                      label-for="vi-schedule-no_ktp_file"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="File KTP"
                        vid="File KTP"
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="item.no_ktp_file"
                          placeholder="Choose a file KTP or drop it here..."
                          accept=".jpeg, .jpg, .png"
                          :state="errors.length > 0 ? false : null"
                        />
                        <!-- @input="inputImageRenderer" -->
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="3" md="3" cols="6">
                    <b-form-group label="Nomor KTP" label-for="vi-forpe-ktp">
                      <validation-provider
                        #default="{ errors }"
                        name="Nomor KTP"
                        vid="vi-forpe-ktp"
                        required="true"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <feather-icon icon="KeyIcon" />
                          </b-input-group-prepend>

                          <cleave
                            id="vi-forpe-ktp"
                            v-model="item.ktp"
                            class="form-control"
                            :raw="true"
                            :options="options.noKTP"
                            placeholder=""
                          />
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col lg="3" md="3" cols="6">
                    <b-form-group
                      label="Nama Lengkap"
                      label-for="vi-forpe-full_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Nama Lengkap"
                        vid="vi-forpe-full_name"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <feather-icon icon="UserIcon" />
                          </b-input-group-prepend>

                          <b-form-input
                            v-uppercase
                            id="full_name"
                            v-model="item.full_name"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Nama Lengkap"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6" md="6" cols="6">
                    <b-form-group label="Alamat" label-for="vi-forpe-address">
                      <validation-provider
                        #default="{ errors }"
                        name="Alamat"
                        vid="vi-forpe-address"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MapIcon" />
                          </b-input-group-prepend>

                          <b-form-input
                            v-uppercase
                            id="address"
                            v-model="item.address"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Alamat"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" cols="6">
                    <b-form-group
                      label="Asal Perusahaan / Instansi"
                      label-for="vi-forpe-from_company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Asal Perusahaan / Instansi"
                        vid="vi-forpe-from_company"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MapIcon" />
                          </b-input-group-prepend>

                          <b-form-input
                            v-uppercase
                            id="from_company"
                            v-model="item.from_company"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Asal Perusahaan / Instansi"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" cols="6">
                    <b-form-group
                      label="Ket. / Tujuan Kunjungan"
                      label-for="vi-forpe-ket"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Ket. / Tujuan Kunjungan"
                        vid="vi-forpe-ket"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MapIcon" />
                          </b-input-group-prepend>

                          <b-form-input
                            v-uppercase
                            id="ket"
                            v-model="item.ket"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Ket. / Tujuan Kunjungan"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- <b-col lg="6" md="6" cols="12">
                  <b-form-group label="Satuan" label-for="vi-forpe-satuan">
                    <validation-provider
                      #default="{ errors }"
                      name="Satuan"
                      vid="vi-forpe-satuan"
                      rules="required"
                    >
                      <v-select
                        id="vi-forpe-satuan"
                        v-model="item.satuan"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :reduce="(option) => option.title"
                        label="title"
                        :options="option.satuan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                </b-row>
                <!-- END OF LOOPING FIELD -->
              </b-col>

              <b-col lg="12" md="12" cols="12">
                <b-button
                  size="md"
                  variant="outline-primary"
                  block
                  @click="repeatePengunjung"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Tambah Pengunjung</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col cols="12" md="12">
        <b-card>
          <validation-observer ref="yoScheduleRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> C </b-badge> Schedule IN
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Planning.</strong>
                </small>
              </b-col>
              <b-col>
                <!-- Calendar for selecting the date -->
                <b-form-group label="">
                  <validation-provider
                    #default="{ errors }"
                    name="Schedule IN Date"
                    rules="required"
                  >
                    <b-calendar
                      v-model="form.schedule_in_date"
                      block
                      locale="id-ID"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Time picker for selecting the time -->
                <b-form-group label="">
                  <validation-provider
                    #default="{ errors }"
                    name="Schedule IN Time"
                    rules="required"
                  >
                    <b-form-timepicker
                      v-model="form.schedule_in_time"
                      now-button
                      reset-button
                      locale="id-ID"
                      :hour12="false"
                      class="mt-2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <strong>
                  <br />
                  <i>" Every scan tells a story of protection and control. "</i>
                </strong>
                <!-- <p>Selected Date: {{ form.schedule_in_date }}</p>
                <p>Selected Time: {{ form.schedule_in_time }}</p> -->
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12" md="12">
        <b-card>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                <b-badge pill variant="primary"> D </b-badge> Short Summary List
                Data QR
              </h5>
              <small class="text-muted">
                <strong
                  >Akan mengirimkan kode QR Ke masing-masing pengunjung secara
                  queue</strong
                >
              </small>
            </b-col>
          </b-row>
          <YoGoodTable
            :fields="tableColumns"
            :data="tableData"
            :paginationOptions="{ enabled: true, perPage: 10 }"
            @edit="handleEdit"
            @delete="handleDelete"
          />
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-button
          :disabled="loadingSaveData"
          block
          @click="formSubmitted"
          variant="primary"
        >
          <b-spinner v-if="loadingSaveData" small />
          Simpan data dan Kirim QRCode Ke Whatsapp
          {{ form.phone ? `+62 ${form.phone}` : `` }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BBadge,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BAvatar,
  BCalendar,
  BFormTimepicker,
  BImg,
  BAlert
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import Tesseract from 'tesseract.js'
import { v4 as uuidv4 } from 'uuid'
// import YoTable from '@/views/components/CompTableT1T2.vue'
import YoGoodTable from '@/views/components/YoGoodTableKhusus.vue'

export default {
  components: {
    // YoTable,
    BAlert,
    YoGoodTable,
    BButton,
    BFormTimepicker,
    BCalendar,
    BBadge,
    Cleave,
    BImg,
    BAvatar,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BInputGroup,
    BInputGroupPrepend,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  data() {
    return {
      /* good table */
      tableColumns: [
        {
          label: 'Badge ( Perusahaan / Instansi )',
          field: 'badge',
          width: '350px'
        },
        { label: 'Nama / Whatsapp', field: 'full_name', width: '200px' },
        { label: 'Ket. / Tujuan Kunjungan', field: 'ket', width: '200px' }
      ],
      tableData: [
        {
          badge: '[ A.1 ] Tamu ( Visitor ) - Umum',
          nomor_whatsapp: '628123456789',
          ktp: '7409011503990011',
          phone: '08154571281',
          full_name: 'Yogi Arif Widodo',
          alamat: 'Jl. Gerbang Dayaku No 1, Jakarta',
          perusahaan: 'PT Sarana Abadi Lestari',
          ket: 'Kunjungan untuk pertemuan bisnis'
        },
        {
          badge: '[ A.2 ] Awak 2 Truck',
          nomor_whatsapp: '628987654321',
          ktp: '6409011503990022',
          phone: '08154571382',
          full_name: 'Arif Setiawan',
          alamat: 'Jl. Raya Industri No 5, Bekasi',
          perusahaan: 'PT Transportasi Jaya',
          ket: 'Pengiriman barang untuk proyek di Jakarta'
        },
        {
          badge: '[ B ] Identitas / Karyawan / Rutinitas',
          nomor_whatsapp: '628112233445',
          ktp: '6409011503990033',
          phone: '08154571483',
          full_name: 'Dina Pratama',
          alamat: 'Jl. Merdeka No 45, Bandung',
          perusahaan: 'PT Bank Negara',
          ket: 'Karyawan untuk pengurusan administrasi'
        },
        {
          badge: '[ C ] Pekerja Harian',
          nomor_whatsapp: '628334455667',
          ktp: '6409011503990044',
          phone: '08154571584',
          full_name: 'Budi Santoso',
          alamat: 'Jl. Pasar No 12, Surabaya',
          perusahaan: 'PT Pekerja Mandiri',
          ket: 'Pekerjaan harian untuk proyek konstruksi'
        },
        {
          badge: '[ D ] Pengurus Agent BBM',
          nomor_whatsapp: '628556677889',
          ktp: '7409011503990055',
          phone: '08154571685',
          full_name: 'Siti Aminah',
          alamat: 'Jl. BBM Raya No 21, Medan',
          perusahaan: 'PT Pertamina',
          ket: 'Pengurusan distribusi bahan bakar minyak'
        }
      ],

      loadingSaveData: false,
      badge: [
        {
          id: 1,
          name: 'Tamu ( Visitor )',
          code: 'A.1',
          desc: 'Umum'
        },
        {
          id: 2,
          name: 'Awak 2 Truck',
          code: 'A.2',
          desc: ''
        },
        {
          id: 3,
          name: 'Identitas / Karyawan / Rutinitas',
          code: 'B',
          desc: ''
        },
        {
          id: 4,
          name: 'Pekerja Harian',
          code: 'C',
          desc: ''
        },
        {
          id: 5,
          name: 'Pengurus Agent BBM',
          code: 'D',
          desc: ''
        }
      ],
      no_ktp_file: {},
      options: {
        phone: {
          // phone: true,
          // phoneRegionCode: 'ID',
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4]
        },
        noKTP: {
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4, 4],
          uppercase: true
        }
      },
      /*
      visitor_public --> id badge_id
      visitor_private --> id badge_id

      ----------------
      ( Reuse QRCode update date_expired and status )
      ( sys_auto_renew = true kalau badge_id = 3 = Reuse QRCode )
      ----------------

      visitor_qrcode --> id model model_id qrcode_img status date_expired sys_auto_renew

      visitor_count --> id id_visitor_qrcode clock_in clock_out diff_time

      ----------------
      index agent
      vw_visitor_qrcode_agent --> id qrcode_img status expired visitor_private ( personal information )

      index user
      vw_visitor_qrcode_user -->  id qrcode_img status expired visitor_public ( personal information )
      ----------------
      */
      form: {
        itemPengunjung: [
          {
            fe_id: 1
            // no_document: '',
            // asal_brg: '',
            // jenis_brg: '',
            // full_name: 0,
            // satuan: 0,
          }
        ],
        nextItemPengunjungID: 2,
        /* personal informations */
        no_ktp_file: '',
        no_ktp: '',
        full_name: '',
        email: '',
        phone: '',

        /* type data / badge
         *
         * Keterangan - Code - Name
         * 1. Umum A.1 Tamu ( Visitor )
         * 2. Jalur Darat A.2 Tamu ( Visitor )
         * 3. '' B Identitas / Karyawan / Rutinitas
         * 4. '' C Pekerja Harian
         * 4. '' 5 Pengurus Agent BBM
         *
         **/
        badge_id: '',

        /* social media */
        twitter: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        schedule_in_date: '',
        schedule_in_time: '',
        uuid: uuidv4(),
        ket: ''
      },
      required,
      email,
      badgeName: [
        { color: '', value: 'select_value', text: 'Pilih Badge' },
        {
          color: 'primary',
          value: '1',
          text: '[ A.1 ] Tamu ( Visitor ) - Umum'
        },
        {
          color: 'primary',
          value: '2',
          text: '[ A.2 ] Awak 2 Truck'
        },
        {
          color: 'success',
          value: '3',
          text: '[ B ] Identitas / Karyawan / Rutinitas'
        },
        {
          color: 'warning',
          value: '4',
          text: '[ C ] Pekerja Harian'
        },
        {
          color: 'success',
          value: '5',
          text: '[ D ] Pengurus Agent BBM'
        }
      ],

      apiEndpoint: null,
      fields: [
        {
          key: 'badge_id',
          label: 'Badge',
          sortable: true,
          input: {
            type: 'select',
            options: [
              {
                label: '[ A.1 ] Tamu ( Visitor ) - Umum',
                value: '1'
              },
              {
                label: '[ A.2 ] Awak 2 Truck',
                value: '2'
              },
              {
                label: '[ B ] Identitas / Karyawan / Rutinitas',
                value: '3'
              },
              {
                label: '[ C ] Pekerja Harian',
                value: '4'
              },
              {
                label: '[ D ] Pengurus Agent BBM',
                value: '5'
              }
            ],
            reduce({ value }) {
              return value
            }
          },
          searchModel: 'badge_id',
          search: {
            type: 'text'
          }
        },
        {
          key: 'phone',
          label: 'Nomor Whatsapp',
          placeholder: '815xxx',
          sortable: true,
          input: { type: 'number-prepend', prepend: '+62' },
          searchModel: 'capacity',
          search: {
            type: 'number'
          }
        },
        {
          key: 'ktp',
          label: 'KTP',
          placeholder: 'xxxxxxxxx',
          sortable: true,
          input: { type: 'number' },
          searchModel: 'ktp',
          search: {
            type: 'number'
          }
        },
        {
          key: 'full_name',
          label: 'Nama Lengkap',
          placeholder: 'Nama Lengkap',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'full_name',
          search: {
            type: 'text'
          }
        },
        {
          key: 'address',
          label: 'Alamat',
          placeholder: 'JL Palaran Indah Lestari',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'address',
          search: {
            type: 'text'
          }
        },
        {
          key: 'from_company',
          label: 'Asal Perusahaan / Instansi',
          placeholder: 'Asal Perusahaan / Instansi',
          sortable: true,
          input: { type: 'input-noarea' },
          searchModel: 'from_company',
          search: {
            type: 'text'
          }
        }
      ]
    }
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)
    const text = ref('') // Create a ref to store the extracted NIK

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64
        extractText(base64)
      }
    )

    // Moved extractText function here so it can access text ref
    const extractText = (base64Image) => {
      Tesseract.recognize(
        base64Image,
        'eng', // use 'ind' if the text is in Indonesian
        {
          logger: (m) => console.log(m) // Optional: log progress
        }
      )
        .then(({ data: { text: extractedText } }) => {
          console.log('Extracted Text:', extractedText)

          // Clean the extracted text by removing non-alphanumeric characters
          // (with exceptions for spaces for clearer parsing)
          const cleanedText = extractedText
            .replace(/[^\w\s:]/g, ' ')
            .replace(/\s+/g, ' ')
            .trim()
          console.log('Cleaned Text:', cleanedText)

          // Use regex to extract NIK number
          const nikMatch = cleanedText.match(/NIK\s*:\s*(\w+)/i)

          if (nikMatch && nikMatch[1]) {
            const nik = nikMatch[1] // get the first capturing group which is the number
            console.log('Extracted NIK:', nik)
            text.value = nik // Store the extracted NIK in the reactive ref
          } else {
            console.log('NIK not found')
            text.value = ''
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      text
    }
  },
  computed: {
    newDataTemplate() {
      return {
        full_name: '',
        no_ktp: '',
        phone: '',
        address: ''
      }
    }
  },
  methods: {
    /* start of of looping field */
    repeatePengunjung() {
      this.form.itemPengunjung.push({
        fe_id: (this.form.nextItemPengunjungID +=
          this.form.nextItemPengunjungID)
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowPengunjung[0].offsetHeight)
      })
    },
    removeItemPengunjung(index) {
      this.form.itemPengunjung.splice(index, 1)
      this.trTrimHeight(this.$refs.rowPengunjung[0].offsetHeight)
    },
    /* end of looping field */

    resolveFetchResponse(response) {
      return response.data.dm_river_boat_hms
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Text copied : ${string}`,
              variant: 'primary',
              icon: 'CheckCircleIcon'
            }
          })
        },
        (e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Can not copy!`,
              variant: 'danger',
              icon: 'XCircleIcon'
            }
          })
        }
      )
    },
    async formSubmitted() {
      try {
        await this.validationA().then(async (_) => {
          await this.validationC().then(async () => {
            await this.validationD().then(async () => {
              await this.validationE().then(() => {
                this.saveData()
              })
            })
          })
        })
      } catch (error) {
        console.log('errors', error)
      }
    },
    async saveData() {
      try {
        this.loadingSaveData = true
        const formData = new FormData()
        formData.append('file', this.form.no_ktp_file)

        // Trim the phone number and remove any spaces
        let phoneNumber = this.form.phone.trim().replace(/\s+/g, '')

        if (phoneNumber.startsWith('0')) {
          // Remove the leading '0' if it exists
          phoneNumber = phoneNumber.slice(1)
        }

        // Now prepend the country code
        const formattedPhone = `62${phoneNumber}`
        console.log('sent to : ', formattedPhone)
        const payload = {
          ...this.form,
          phone: `${formattedPhone}`
          // img_url: `https://salpalaran.com/img/faq-illustrations.06fc90e5.svg`,
          // desc: `QRCode`,
          // msg_text: `[QRCode] Anda berhasil membuat planning tujuan ke PT Sarana Abadi Lestari`,
        }
        // Append the payload as a JSON string
        // formData.append('payload', JSON.stringify(payload))

        await useJwt.http
          .post('visitor_public/', payload)
          .then(async (result) => {
            try {
              const { id } = result.data.visitor_public
              await useJwt.http
                .put(
                  `file_storage_ktp/${id}/visitor_public/${payload.no_ktp}`,
                  formData
                )
                .then(async (_result) => {
                  console.log('[post ktp] [0] success on upload ktp', _result)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'QRCode Telah dikirim ke Whatsapp Kamu',
                      icon: 'SaveIcon',
                      variant: 'success'
                    }
                  })
                  const successData = {
                    full_name: this.form.full_name,
                    phone: this.form.phone
                  }

                  // Store the data in Vuex
                  this.$store.commit('visitor/SET_SUCCESS_DATA', successData)

                  this.$router.push({
                    path: '/planning/create-form-khusus/success'
                  })
                  // await this.sentNotification('Published')
                })
                .catch((errors) => {
                  this.loadingSaveData = false
                  console.log('[post ktp] [1] an errors on upload ktp', errors)
                })
            } catch (errors) {
              this.loadingSaveData = false
              console.log('[post ktp] [2] an errors on upload ktp', errors)
            }
          })
          .catch((e) => {
            this.loadingSaveData = false
            console.log('error: ', e)
            console.log('error: ', e.data.message.default)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tidak berhasil melakukan aksi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
          })
      } catch (error) {
        console.log('an errors', error)
      }
    },
    // async sentNotification(isRevisiMsg) {
    //   await this.setPayloadNotification(isRevisiMsg)
    //   useJwt.http
    //     .post('notification', this.payloadNotification)
    //     .then(() => {})
    //     .catch((error) => {
    //       // console.log('data err: ', err)
    //       this.$swal({
    //         title: 'Error!',
    //         text: ` Form Masuk dan Keluar Berhasil dibuat tetapi ada kesalahan sistem untuk notification! ${error}. Ini tidak berdampak pada kerja sistem utama.`,
    //         icon: 'error',
    //         customClass: {
    //           confirmButton: 'btn btn-primary'
    //         },
    //         buttonsStyling: false
    //       }).then(async () => {
    //         if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
    //         this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
    //       })
    //     })
    // },
    validationA() {
      return new Promise((resolve, reject) => {
        this.$refs.yoBadgeRule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
            reject()
          }
        })
      })
    },
    validationC() {
      return new Promise((resolve, reject) => {
        this.$refs.yoPersonalInfoRule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
            reject()
          }
        })
      })
    },
    validationD() {
      return new Promise((resolve, reject) => {
        this.$refs.yoScheduleRule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
            reject()
          }
        })
      })
    },
    validationE() {
      return new Promise((resolve, reject) => {
        this.$refs.yoScheduleOthers.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
            reject()
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
