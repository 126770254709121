var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('b-alert',{staticClass:"cursor-pointer",attrs:{"variant":'primary',"show":""}},[_c('div',{staticClass:"alert-body"},[_c('strong',[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"BookIcon"}}),_vm._v(" Monitoring is more than just watching—it's proactive engagement. With real-time visitor tracking, our agents are always one step ahead, ensuring safety and smooth operations. ")],1)])]),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-card',{staticClass:"py-1",attrs:{"no-body":""}},[_c('b-form',{ref:"formListQR",staticClass:"repeater-form ml-2 mr-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},_vm._l((_vm.form.itemPengunjung),function(item,index){return _c('b-row',{key:item.fe_id,ref:"rowPengunjung",refInFor:true,staticClass:"repeater-form ml-1 mr-1",attrs:{"id":item.fe_id}},[_c('b-col',{attrs:{"cols":"1"}},[_c('b-badge',{staticClass:"mt-0",attrs:{"pill":"","variant":"info"}},[_vm._v(" "+_vm._s(index + 1)+" ")])],1),_c('b-col',{attrs:{"cols":"10"}},[_c('hr')]),_c('b-col',{attrs:{"cols":"1"}},[_c('b-badge',{attrs:{"pill":"","variant":"danger"}},[_c('feather-icon',{style:({ cursor: 'pointer' }),attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.removeItemPengunjung(index)}}})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","cols":"6"}},[_c('b-form-group',{attrs:{"label":"Badge","label-for":"vi-forpe-badge_id"}},[_c('validation-provider',{attrs:{"name":"Badge","vid":"vi-forpe-badge_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-forpe-badge_id","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.badgeName,"reduce":function (option) { return option.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text","item-value":"value","item-text":"text"},model:{value:(item.badge_id),callback:function ($$v) {_vm.$set(item, "badge_id", $$v)},expression:"item.badge_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","cols":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor Whatsapp * ( Ex: 8155 7777 33 )","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Nomor Whatsapp *","vid":"phone","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" ID (+62) ")]),_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"placeholder":"8155 7777 33","state":errors.length > 0 ? false : null},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","cols":"12"}},[_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"","label-for":"vi-schedule-no_ktp_file"}},[_c('validation-provider',{attrs:{"name":"File KTP","vid":"File KTP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",refInFor:true,attrs:{"placeholder":"Choose a file KTP or drop it here...","accept":".jpeg, .jpg, .png","state":errors.length > 0 ? false : null},model:{value:(item.no_ktp_file),callback:function ($$v) {_vm.$set(item, "no_ktp_file", $$v)},expression:"item.no_ktp_file"}})]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"3","cols":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor KTP","label-for":"vi-forpe-ktp"}},[_c('validation-provider',{attrs:{"name":"Nomor KTP","vid":"vi-forpe-ktp","required":"true"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"KeyIcon"}})],1),_c('cleave',{staticClass:"form-control",attrs:{"id":"vi-forpe-ktp","raw":true,"options":_vm.options.noKTP,"placeholder":""},model:{value:(item.ktp),callback:function ($$v) {_vm.$set(item, "ktp", $$v)},expression:"item.ktp"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"3","cols":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Lengkap","label-for":"vi-forpe-full_name"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","vid":"vi-forpe-full_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"full_name","state":errors.length > 0 ? false : null,"placeholder":"Nama Lengkap"},model:{value:(item.full_name),callback:function ($$v) {_vm.$set(item, "full_name", $$v)},expression:"item.full_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","cols":"6"}},[_c('b-form-group',{attrs:{"label":"Alamat","label-for":"vi-forpe-address"}},[_c('validation-provider',{attrs:{"name":"Alamat","vid":"vi-forpe-address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MapIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"address","state":errors.length > 0 ? false : null,"placeholder":"Alamat"},model:{value:(item.address),callback:function ($$v) {_vm.$set(item, "address", $$v)},expression:"item.address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","cols":"6"}},[_c('b-form-group',{attrs:{"label":"Asal Perusahaan / Instansi","label-for":"vi-forpe-from_company"}},[_c('validation-provider',{attrs:{"name":"Asal Perusahaan / Instansi","vid":"vi-forpe-from_company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MapIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"from_company","state":errors.length > 0 ? false : null,"placeholder":"Asal Perusahaan / Instansi"},model:{value:(item.from_company),callback:function ($$v) {_vm.$set(item, "from_company", $$v)},expression:"item.from_company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","cols":"6"}},[_c('b-form-group',{attrs:{"label":"Ket. / Tujuan Kunjungan","label-for":"vi-forpe-ket"}},[_c('validation-provider',{attrs:{"name":"Ket. / Tujuan Kunjungan","vid":"vi-forpe-ket"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MapIcon"}})],1),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"ket","state":errors.length > 0 ? false : null,"placeholder":"Ket. / Tujuan Kunjungan"},model:{value:(item.ket),callback:function ($$v) {_vm.$set(item, "ket", $$v)},expression:"item.ket"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),1),_c('b-col',{attrs:{"lg":"12","md":"12","cols":"12"}},[_c('b-button',{attrs:{"size":"md","variant":"outline-primary","block":""},on:{"click":_vm.repeatePengunjung}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Tambah Pengunjung")])],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-card',[_c('validation-observer',{ref:"yoScheduleRule",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" C ")]),_vm._v(" Schedule IN ")],1),_c('small',{staticClass:"text-muted"},[_c('strong',[_vm._v("Enter Your Planning.")])])]),_c('b-col',[_c('b-form-group',{attrs:{"label":""}},[_c('validation-provider',{attrs:{"name":"Schedule IN Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-calendar',{attrs:{"block":"","locale":"id-ID"},model:{value:(_vm.form.schedule_in_date),callback:function ($$v) {_vm.$set(_vm.form, "schedule_in_date", $$v)},expression:"form.schedule_in_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":""}},[_c('validation-provider',{attrs:{"name":"Schedule IN Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"mt-2",attrs:{"now-button":"","reset-button":"","locale":"id-ID","hour12":false},model:{value:(_vm.form.schedule_in_time),callback:function ($$v) {_vm.$set(_vm.form, "schedule_in_time", $$v)},expression:"form.schedule_in_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('strong',[_c('br'),_c('i',[_vm._v("\" Every scan tells a story of protection and control. \"")])])],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" D ")]),_vm._v(" Short Summary List Data QR ")],1),_c('small',{staticClass:"text-muted"},[_c('strong',[_vm._v("Akan mengirimkan kode QR Ke masing-masing pengunjung secara queue")])])])],1),_c('YoGoodTable',{attrs:{"fields":_vm.tableColumns,"data":_vm.tableData,"paginationOptions":{ enabled: true, perPage: 10 }},on:{"edit":_vm.handleEdit,"delete":_vm.handleDelete}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"disabled":_vm.loadingSaveData,"block":"","variant":"primary"},on:{"click":_vm.formSubmitted}},[(_vm.loadingSaveData)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Simpan data dan Kirim QRCode Ke Whatsapp "+_vm._s(_vm.form.phone ? ("+62 " + (_vm.form.phone)) : "")+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }